import React, { useEffect, useRef } from "react";
import CallToAction from "components/Call-to-action/call-to-action";
import PagesHeader from "components/Pages-header";
// import AboutIntro from "components/About-intro";

import { ArrowUpFromDot } from "lucide-react";
import { useLocal } from "utils/useLocalContent";
//import Split from "components/Split/split";



const sliderDataDe = [
  {
    title: "📌 Kreative & Moderne Designs",
    text: "Wir gestalten visuell beeindruckende und benutzerfreundliche Websites, die im Gedächtnis bleiben."
  },
  {
    title: "⚡ Expertenentwicklung",
    text: "Unsere erfahrenen Entwickler erstellen schnelle, responsive und leistungsstarke Websites, die auf Ihre Bedürfnisse zugeschnitten sind."
  },
  {
    title: "🎬 VFX & Videobearbeitung",
    text: "Von beeindruckenden Animationen bis zu nahtlosen Schnitten – wir erwecken Ihre Vision mit filmischer Qualität zum Leben."
  },
  {
    title: "📈 Social Media Wachstum",
    text: "Wir entwickeln fesselnde Inhalte und Strategien, um die Online-Präsenz und Reichweite Ihrer Marke zu steigern."
  },
  {
    title: "💡 Umfassende Digitale Lösungen",
    text: "Von Webdesign bis Marketing bieten wir Komplettlösungen, um Ihr Unternehmen online voranzubringen."
  },
  {
    title: "🚀 Zuverlässige & Pünktliche Lieferung",
    text: "Wir schätzen Ihre Zeit und liefern hochwertige Ergebnisse pünktlich."
  }
];

const sliderDataEn = [
  {
    title: "📌 Creative & Modern Designs",
    text: "We craft visually stunning and user-friendly websites that leave a lasting impression."
  },
  {
    title: "⚡ Expert Development",
    text: "Our skilled developers build fast, responsive, and high-performing websites tailored to your needs."
  },
  {
    title: "🎬 VFX & Video Editing",
    text: "From eye-catching animations to seamless edits, we bring your vision to life with cinematic quality."
  },
  {
    title: "📈 Social Media Growth",
    text: "We create engaging content and strategies to boost your brand’s online presence and reach."
  },
  {
    title: "💡 Complete Digital Solutions",
    text: "From web design to marketing, we provide all-in-one solutions to elevate your business online."
  },
  {
    title: "🚀 Reliable & Timely Delivery",
    text: "We value your time and ensure high-quality results delivered on schedule."
  }
];

const OurGoalsContent = [
  {
    "heading": "Deliver Excellence",
    "description": "Providing high-quality digital solutions that stimulate growth and engagement.",
    "main": false,
    "img": "/img/retro2.jpg",
  },
  {
    "heading": "Innovate Continuously",
    "description": "Staying ahead of digital trends and technologies to offer cutting-edge solutions.",
    "main": true,
    "img": "/img/retro1.jpg"
  },
  {
    "heading": "Build Relationships",
    "description": "Cultivating enduring client relationships through exemplary service and support.",
    "main": false,
    "img": "/img/retro3.jpg"
  }
]

const OurGoalsContentDe = [
  {
    "heading": "Exzellenz liefern",
    "description": "Bereitstellung hochwertiger digitaler Lösungen, die Wachstum und Engagement fördern.",
    "main": false,
    "img": "/img/retro2.jpg",
  },
  {
    "heading": "Kontinuierlich innovieren",
    "description": "Den digitalen Trends und Technologien voraus sein, um modernste Lösungen anzubieten.",
    "main": true,
    "img": "/img/retro1.jpg"
  },
  {
    "heading": "Beziehungen aufbauen",
    "description": "Pflege dauerhafter Kundenbeziehungen durch vorbildlichen Service und Support.",
    "main": false,
    "img": "/img/retro3.jpg"
  }

]

const About = () => {

  const lang = useLocal()
  const titleIntro = lang === 'de' ? "Wer wir sind?" : "Who We Are ?"
  const textIntro = lang === 'de' ? "Valvie ist ein Team leidenschaftlicher Fachleute, die sich der Verbesserung Ihrer digitalen Präsenz verschrieben haben. Wir sind spezialisiert auf die Gestaltung exquisiter Websites, die Produktion fesselnder Videos und die Entwicklung umfassender digitaler Strategien, um Ihre Produkte und Dienstleistungen gekonnt zu präsentieren. Unsere Mission ist es, Ihre Vision mit unvergleichlicher Kreativität, Innovation und Präzision zum Leben zu erwecken."
    : "Valvie is a team of impassioned professionals committed to enhancing your digital footprint. We specialize in designing exquisite websites, producing captivating videos, and crafting comprehensive digital strategies to adeptly showcase your products and services. Our mission is to bring your vision to life with unparalleled creativity, innovation, and precision."

  return (
    <>
      <PagesHeader />
      <AboutIntroInline title={titleIntro} text={textIntro} />

      <OurGoals />

      <Slider />
      <CallToAction />
    </>
  );
};

export const Head = () => {
  return (
    <>
      <title>About us</title>
    </>
  )
}

export default About;


export const OurGoals = () => {


  // const lang = useLocale()

  const [lang] = useLocal()
  const content = lang === "de" ? OurGoalsContentDe : OurGoalsContent;

  return (
    <div style={{
      padding: "100px 20px",
      maxWidth: '1200px',
      marginInline: 'auto',
      opacity: '0.95',
    }}>

      <div style={{
        marginBottom: '1.3rem',
        textAlign: 'center',
      }}>
        <h2>{lang !== 'de' ? 'Our Goals' : 'Unsere Ziele'}</h2>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.4rem',
        padding: '1rem',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'hidden',
      }}>
        {content.map((card, index) => {
          return (
            <CardCo
              key={index}
              title={card.heading}
              text={card.description}
              main={card.main ? true : false}
              img={card.img}
            />
          );
        })}
      </div>
    </div>
  )
}



const CardCo = ({ title, text, main = false, img = null }) => {

  const card = useRef(null);

  const highlighter = useRef(null);

  useEffect(() => {
    if (card.current) {
      card.current.addEventListener('mouseover', () => {
        card.current.style.boxShadow = '0px 50px 70px rgba(147, 51, 234, 0.5)';
        card.current.style.outline = '2px solid #13B3D7';
      });
      card.current.addEventListener('mouseout', () => {
        card.current.style.boxShadow = '0px 50px 40px rgba(147, 51, 234, 0.1)';
        card.current.style.outline = '1.5px solid rgba(128, 128, 128, 0.4)';
      });
    }

    if (main && card.current) {
      card.current.style.height = '600px';
    }

  }, [main]);


  return (
    <div style={{
      position: 'relative',
      width: 'clamp(300px, 30%, 400px)',
      height: '400px',
      borderRadius: '8px',
      overflow: 'hidden',
      padding: '1.5rem',
      outline: '1.5px solid rgba(128, 128, 128, 0.4)',
      boxShadow: '0px 50px 40px rgba(147, 51, 234, 0.1)',
      transition: 'box-shadow 0.3s ease',
      marginBottom: '4rem',
    }} ref={card} >
      <div>
        <h2 style={{
          fontSize: '1.5rem',
          marginBottom: '1rem',
          fontWeight: 'bold',
          color: '#f1f1f1',
        }}>{title}</h2>
        <p style={{
          fontSize: '1rem',
          color: '#f1f1f1',
          opacity: '0.8',
        }}>{text}</p>
      </div>

      {img && <div style={{
        height: '100%',
        width: '100%',
        marginTop: '1rem',
      }
      }>
        <img src={img} alt="" style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          borderRadius: '10px 10px 0 0',
        }} />
      </div>}

      <div style={{
        position: 'absolute',
        inset: '0',
        backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
        zIndex: '-1',
        opacity: '0.2',
      }} />
      <div ref={highlighter} />
    </div>
  )
}






const Slider = () => {

  const scrollingCo = useRef(null)


  const [lang] = useLocal()
  const sliderData = lang === "de" ? sliderDataDe : sliderDataEn;

  const sliderItemWidth = scrollingCo.current?.firstChild?.getBoundingClientRect()?.width || 400;

  // const sliderItemWidth = 400

  return (
    <div style={{

      //backgroundColor: "rgb(155, 155, 255, 0.1)",
      padding: "100px 20px",
      position: 'relative',
    }}>
      <div className="bg" style={{
        position: 'absolute',
        inset: '0',
        opacity: '0.6',
        zIndex: '-1',
      }}>
        <img src="https://n0xtunjqxyj91w6z.public.blob.vercel-storage.com/valvie-website/images/glassBg-0Dg5xYyZU4HquHUoMkySSo2o7IAkBq.jpg" alt="" style={{
          width: '100%',
          height: '100%'
        }} />
      </div>

      <div style={{
        maxWidth: '1200px',
        marginInline: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1rem',
      }}>
        <h2>{lang === "de" ? 'Warum wir' : 'Why us'}</h2>

        <div className="buttons-co-slider" style={{
          display: 'flex',
          rowGap: '2rem',
        }}>
          <ArrowUpFromDot
            // ref={leftArrow}
            onClick={() => {
              scrollingCo.current.scrollBy({
                left: - sliderItemWidth,
                behavior: 'smooth'
              });
            }}
            size={40} style={{
              border: '1px solid rgb(255,255,255,0.4)',
              padding: '0.5rem',
              borderRadius: '50%',
              transform: 'rotate(-90deg)',
              color: 'rgba(255, 255, 255, 0.8)',
              cursor: 'pointer',
              marginRight: '1rem'
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = '#13B3D7'
              e.currentTarget.style.border = '1px solid #13B3D7'
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = 'rgba(255, 255, 255, 0.8)'
              e.currentTarget.style.border = '1px solid rgb(255,255,255,0.4)'
            }} />

          <ArrowUpFromDot
            // ref={rightArrow}
            onClick={() => {
              scrollingCo.current.scrollBy({
                left: sliderItemWidth,
                behavior: 'smooth'
              })
            }}
            size={40}
            style={{
              border: '1px solid rgb(255,255,255,0.4)',
              padding: '0.5rem',
              borderRadius: '50%',
              transform: 'rotate(90deg)',
              color: 'rgba(255, 255, 255, 0.8)',
              cursor: 'pointer'
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = '#13B3D7'
              e.currentTarget.style.border = '1px solid #13B3D7'
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = 'rgba(255, 255, 255, 0.8)'
              e.currentTarget.style.border = '1px solid rgb(255,255,255,0.4)'
            }}
          />
        </div>
      </div>
      <div ref={scrollingCo}
        style={{
          display: 'flex',
          overflowX: 'auto',

          scrollSnapAlign: 'start',
          padding: '1rem',
          maxWidth: '1200px',
          marginInline: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          msOverflowStyle: 'none',
          paddingRight: '7rem',
          maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 95%)',
          WebkitMaskImage: 'linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 95%)'
        }}>



        {sliderData.map((card, index) => {
          return (
            <div className="scroll-card" style={{
              minWidth: 'clamp(300px, 30%, 400px)',
            }} key={index + 1 + 'scroll-card'}>
              <CardCo title={card.title} text={card.text} />
            </div>
          );
        })}
      </div>
    </div>
  )
}



const AboutIntroInline = ({ title = '', text = '' }) => {
  const textCo = useRef(null)
  const [lang] = useLocal()

  const spotLight = useRef(null)
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (spotLight.current && textCo.current) {
        const rect = textCo.current.getBoundingClientRect();
        const isInside = e.clientX >= rect.left && e.clientX <= rect.right &&
          e.clientY >= rect.top && e.clientY <= rect.bottom;

        spotLight.current.style.display = isInside ? 'block' : 'none';
        if (isInside) {
          spotLight.current.style.left = `${e.clientX - rect.left}px`;
          spotLight.current.style.top = `${e.clientY - rect.top}px`;
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    console.log('switched')
    // Cleanup function
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [lang]);



  return (
    <section className="intro-sections pb-0">
      <div className="container" style={{ paddingTop: "90px" }}>
        <div className="row" style={{ paddingTop: "50px" }}>
          <div className="col-lg-3 col-md-4">
            <div className="htit sm-mb30" >
              <h4>{title}</h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text" ref={textCo}
              style={{
                position: 'relative',
              }}>
              {/* <Split> */}
              <p
                className="wow txt mb-10 words chars splitting"
                data-splitting
                style={{
                  opacity: 0.8,
                  backgroundImage: 'linear-gradient(to right, rgb(255,255,255,0.4) 0%, white 50%, white 50%, rgb(255,255,255,0.4) 100%)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  color: 'transparent',
                  position: 'relative',
                }}
              >

                {text}


                <span ref={spotLight} className="text-bg" style={{
                  position: 'absolute',
                  width: '150px',
                  height: '150px',
                  background: 'white',
                  mixBlendMode: 'difference',
                  borderRadius: '50%',
                  pointerEvents: 'none',
                  transform: 'translate(-50%, -50%)',
                  zIndex: '-1', scale: 1,
                  transition: 'scale 0.1s ease-out',
                }} />
              </p>
              {/* </Split> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
